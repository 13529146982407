import React from 'react';

// Antd Components
import { Carousel as AntCarousel } from 'antd';

import { CarouselProps } from 'antd/lib/carousel';

import styled from 'styled-components';

type TProps = {
    height?: number;
    images: Array<{ key: string; src: string; mobileImg?: string; alt: string }>;
    navigation?: boolean;
    pagination?: boolean;
    speed?: number;
};

const AntSwiper = styled(AntCarousel)<CarouselProps>`
    & .slick-slide img {
        filter: brightness(70%);
    }
    &&& {
        @media only screen and (max-width: 599px) {
            display: none;
        }
    }
`;

const AntSwiperMobile = styled(AntCarousel)<CarouselProps>`
    & .slick-slide img {
        filter: brightness(70%);
    }
    &&& {
        @media only screen and (min-width: 600px) {
            display: none;
        }
    }
`;
const Carousel = ({ height, images, navigation, pagination, speed }: TProps): JSX.Element => {
    const mobileImages = images.filter(image => {
        return !!image.mobileImg;
    });
    return (
        <>
            <AntSwiper
                key="desktop-carousel"
                autoplay
                dots={pagination || true}
                effect="fade"
                arrows={navigation || true}
                speed={speed || 4000}
                adaptiveHeight>
                {images.map((image, ix) => {
                    return (
                        <div key={`desktop-${image.key}-${ix + 1}`}>
                            <img
                                src={image.src}
                                alt={image.alt}
                                height={`${height || 450}px`}
                                key={`desktop-${image.key}-${ix + 1}`}
                                style={{
                                    filter: 'brightness(60%)',
                                    display: 'block',
                                    marginRight: 'auto',
                                    marginLeft: 'auto',
                                }}
                            />
                        </div>
                        // <picture key={image.key} style={{ width: '100%' }}>
                        //     <source srcSet={image.src} media="(min-width: 600px)" />
                        //     {image.mobileImg ? (
                        //         <img src={image.mobileImg ? image.mobileImg : ''} alt="PP" style={{ width: '100%' }} />
                        //     ) : null}
                        // </picture>
                    );
                })}
            </AntSwiper>
            <AntSwiperMobile
                key="mobile-carousel"
                autoplay
                dots={pagination || true}
                effect="fade"
                arrows={navigation || true}
                speed={speed || 4000}
                adaptiveHeight>
                {mobileImages.length > 0
                    ? mobileImages.map((image, ix) => {
                          return (
                              <div key={`mobile-${image.key}-${ix + 1}`}>
                                  <img
                                      src={image.mobileImg}
                                      alt={image.alt}
                                      height="500px"
                                      key={`mobile-${image.key}-${ix + 1}`}
                                      style={{ filter: 'brightness(60%)' }}
                                  />
                              </div>
                              // <picture key={image.key} style={{ width: '100%' }}>
                              //     <source srcSet={image.src} media="(min-width: 600px)" />
                              //     {image.mobileImg ? (
                              //         <img src={image.mobileImg ? image.mobileImg : ''} alt="PP" style={{ width: '100%' }} />
                              //     ) : null}
                              // </picture>
                          );
                      })
                    : images.map((image, ix) => {
                          return (
                              <img
                                  src={image.src}
                                  alt={image.alt}
                                  key={`mobile-single-${image.key}-${ix + 1}`}
                                  style={{ filter: 'brightness(60%)' }}
                              />
                              // <picture key={image.key} style={{ width: '100%' }}>
                              //     <source srcSet={image.src} media="(min-width: 600px)" />
                              //     {image.mobileImg ? (
                              //         <img src={image.mobileImg ? image.mobileImg : ''} alt="PP" style={{ width: '100%' }} />
                              //     ) : null}
                              // </picture>
                          );
                      })}
            </AntSwiperMobile>
        </>
    );
};

export default Carousel;
