import React from 'react';

import styled from 'styled-components';

const Container = styled.div`
    opacity: 0;
    transform: translateY(10px);
    visibility: hidden;
    transition: opacity 0.25s ease-out, transform 0.25s ease-out;
    will-change: opacity, visibility;
`;

const FadeInSection = ({ children }: { children: JSX.Element[] | JSX.Element }): JSX.Element => {
    const [isVisible, setVisible] = React.useState(true);
    const domRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (domRef.current) {
                entries.forEach(entry => setVisible(entry.isIntersecting));
            }
        });

        const domRefCurrent = domRef.current;

        if (domRefCurrent !== null) {
            observer.observe(domRefCurrent);
        }

        return (): void => {
            if (domRefCurrent !== null) {
                observer.unobserve(domRefCurrent);
            }
        };
    }, []);

    return (
        <Container style={isVisible ? { opacity: 1, transform: 'none', visibility: 'visible' } : {}} ref={domRef}>
            {children}
        </Container>
    );
};

export default FadeInSection;
