import React from 'react';

// Antd Components
import { Typography as AntTypography } from 'antd';

import { TitleProps } from 'antd/lib/typography/Title';

import styled from 'styled-components';

import { TVariant, TTextType } from '../types/cms';

const { Text, Title } = AntTypography;

type TProps = {
    content: JSX.Element;
    variant?: TVariant;
    color?: TTextType;
    key?: string;
};

// styled components
const TextBlock = styled(AntTypography)<TitleProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        font-size: 15px;
        margin-top: 5px;
        text-align: justify;
        color: #5e5e5e;
        white-space: pre;
    }
`;

const Header = styled(Title)<TitleProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        font-weight: 500;
        margin-top: 5px;
    }
`;

const Button = styled(Text)<TitleProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        font-size: 16px;
        font-weight: 500;
        margin-top: 5px;
    }
`;

const Caption = styled(Text)<TitleProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        font-size: 14px;
        margin: 5px 0px;
    }
`;

const fontSizes = ['36px', '30px', '24px', '18.72px', '18px', '17px'];

const Typography = ({ color, content, key, variant }: TProps): JSX.Element => {
    switch (variant) {
        case 'h1':
            return (
                <Header
                    key={key}
                    // eslint-disable-next-line no-nested-ternary
                    style={{ fontSize: fontSizes[0], color: color ? undefined : color === null ? 'black' : '#3f51b5' }}
                    type={color}>
                    {content}
                </Header>
            );
        case 'h2':
            return (
                <Header key={key} style={{ fontSize: fontSizes[1], color: color ? undefined : '#3f51b5' }} type={color}>
                    {content}
                </Header>
            );
        case 'h3':
            return (
                <Header
                    key={key}
                    style={{
                        fontSize: fontSizes[2],
                        // eslint-disable-next-line no-nested-ternary
                        color: color ? undefined : color === 'none' ? 'black' : '#3f51b5',
                    }}
                    type={color}>
                    {content}
                </Header>
            );
        case 'h4':
            return (
                <Header key={key} style={{ fontSize: fontSizes[3], color: color ? undefined : '#3f51b5' }} type={color}>
                    {content}
                </Header>
            );
        case 'h5':
            return (
                <Header key={key} style={{ fontSize: fontSizes[4], color: color ? undefined : '#3f51b5' }} type={color}>
                    {content}
                </Header>
            );
        case 'h6':
            return (
                <Header key={key} style={{ fontSize: fontSizes[5], color: color ? undefined : '#3f51b5' }} type={color}>
                    {content}
                </Header>
            );
        case 'button':
            return (
                <Button key={key} type={color}>
                    {content}
                </Button>
            );
        case 'caption':
            return (
                <Caption key={key} type={color}>
                    {content}
                </Caption>
            );
        default:
            return (
                <TextBlock key={key} type={color}>
                    {content}
                </TextBlock>
            );
    }
};

export default Typography;
