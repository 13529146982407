/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import * as Sentry from '@sentry/browser';

// Antd Components
import {
    Typography as AntTypography,
    Image,
    Divider,
    List as AntList,
    Row,
    Col,
    Table as AntTable,
    Alert as AntAlert,
    Button,
} from 'antd';

// AntD Icons
import { CaretRightOutlined, UserOutlined, TeamOutlined, InfoCircleOutlined } from '@ant-design/icons';

import { Parser } from 'html-to-react';
import { TitleProps } from 'antd/lib/typography/Title';
import styled from 'styled-components';
import { TCMS, TListIcon } from '../types/cms';
import SectionHeader from '../components/SectionHeader';
import Carousel from '../components/Carousel';
import FadeInSection from '../components/FadeInSection';
import StyledHeader from '../components/StyledHeader';
import Typography from '../components/Typography';

const { Text } = AntTypography;
const { Column } = AntTable;

const IS_BROWSER = typeof window !== 'undefined';

// styled components
const TextBlock = styled(Text)<TitleProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        font-size: 16px;
        margin-bottom: 20px;
        white-space: pre;
    }
`;

const GridItem = styled.div`
    &&& {
        @media only screen and (max-width: 1155px) {
            height: 200px;
        }
        height: 320px;
        position: relative;
        text-align: center;
        color: 'white';
        margin-bottom: 0;
        overflow: hidden;
    }
`;

export const getIconFor = (iconName?: TListIcon): JSX.Element => {
    switch (iconName) {
        case 'arrow':
            return <CaretRightOutlined />;
        case 'circle':
            return <InfoCircleOutlined />;
        case 'person1':
            return <UserOutlined />;
        case 'person2':
            return <TeamOutlined />;
        default:
            Sentry.captureEvent({ message: 'Invalid icon name' });
            return <div />;
    }
};

// #TODO: @Hiruni: parseContent function (refer #engineering)
const parseContent = (content: string): JSX.Element => {
    if (typeof content === 'string') {
        const HtmlToReactParser = Parser();
        const parsedContent = content
            .replace('<red>', `<span style="color:red">`)
            .replace('</red>', '</span>')
            .replace(/\r\n|\r|\n/g, '</br>');
        return <span style={{ whiteSpace: 'pre-wrap' }}>{HtmlToReactParser.parse(parsedContent)}</span>;
    }
    return <></>;
};

export const renderContent = (elements: Array<TCMS>): JSX.Element[] => {
    return elements.map((element, i) => {
        switch (element.type) {
            case 'video': {
                let videoCount = 0;
                if (element.content.videoId1) videoCount += 1;
                if (element.content.videoId2) videoCount += 1;
                if (element.content.videoId3) videoCount += 1;
                let isMobile = false;
                if (IS_BROWSER && window.innerWidth < 600) isMobile = true;
                if (element.content.host === 'vimeo') {
                    if (!isMobile) {
                        return (
                            <>
                                {element.content.title ? <h3>{element.content.title}</h3> : null}
                                {element.content.description ? <p>{element.content.description}</p> : null}
                                <table style={{ width: '100%' }}>
                                    <tr>
                                        {element.content.videoId1 ? (
                                            <td>
                                                <iframe
                                                    src={`https://player.vimeo.com/video/${element.content.videoId1}`}
                                                    style={{ position: 'relative', width: '100%' }}
                                                    height={`${464 / videoCount}`}
                                                    frameBorder="0"
                                                    allow="autoplay; fullscreen; picture-in-picture"
                                                    allowFullScreen
                                                    title={element.content.title || 'Video player'}
                                                />
                                            </td>
                                        ) : null}
                                        {element.content.videoId2 ? (
                                            <td>
                                                <iframe
                                                    src={`https://player.vimeo.com/video/${element.content.videoId2}`}
                                                    style={{ position: 'relative', width: '100%' }}
                                                    height={`${464 / videoCount}`}
                                                    frameBorder="0"
                                                    allow="autoplay; fullscreen; picture-in-picture"
                                                    allowFullScreen
                                                    title={element.content.title || 'Video player'}
                                                />
                                            </td>
                                        ) : null}
                                        {element.content.videoId3 ? (
                                            <td>
                                                <iframe
                                                    src={`https://player.vimeo.com/video/${element.content.videoId3}`}
                                                    style={{ position: 'relative', width: '100%' }}
                                                    height={`${464 / videoCount}`}
                                                    frameBorder="0"
                                                    allow="autoplay; fullscreen; picture-in-picture"
                                                    allowFullScreen
                                                    title={element.content.title || 'Video player'}
                                                />
                                            </td>
                                        ) : null}
                                    </tr>
                                </table>
                            </>
                        );
                    }
                    return (
                        <>
                            {element.content.title ? <h3>{element.content.title}</h3> : null}
                            {element.content.description ? <p>{element.content.description}</p> : null}
                            <table style={{ width: '100%' }}>
                                {element.content.videoId1 ? (
                                    <tr>
                                        <td>
                                            <iframe
                                                src={`https://player.vimeo.com/video/${element.content.videoId1}`}
                                                style={{ position: 'relative', width: '100%' }}
                                                height="464"
                                                frameBorder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowFullScreen
                                                title={element.content.title || 'Video player'}
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                                {element.content.videoId2 ? (
                                    <tr>
                                        <td>
                                            <iframe
                                                src={`https://player.vimeo.com/video/${element.content.videoId2}`}
                                                style={{ position: 'relative', width: '100%' }}
                                                height="464"
                                                frameBorder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowFullScreen
                                                title={element.content.title || 'Video player'}
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                                {element.content.videoId3 ? (
                                    <tr>
                                        <td>
                                            <iframe
                                                src={`https://player.vimeo.com/video/${element.content.videoId3}`}
                                                style={{ position: 'relative', width: '100%' }}
                                                height="464"
                                                frameBorder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowFullScreen
                                                title={element.content.title || 'Video player'}
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                            </table>
                        </>
                    );
                }
                return <></>;
            }
            case 'image-grid': {
                return (
                    <FadeInSection>
                        <Row gutter={[0, 0]} wrap>
                            {element.content.map(galleryItem => (
                                <Col key={`gallery-tile-${galleryItem.src}`} xs={24} sm={12}>
                                    <GridItem>
                                        <Image
                                            src={`${galleryItem.src}`}
                                            alt={galleryItem.title}
                                            style={{ width: '100%', overflow: 'hidden', height: '100%' }}
                                        />
                                        <TextBlock
                                            style={{
                                                position: 'absolute',
                                                bottom: 0,
                                                right: 0,
                                                left: 0,
                                                padding: '5px',
                                                color: 'white',
                                                background: 'rgba(0, 0, 0, 0.6)',
                                                marginBottom: 0,
                                            }}>
                                            {galleryItem.title}
                                        </TextBlock>
                                    </GridItem>
                                </Col>
                            ))}
                        </Row>
                    </FadeInSection>
                );
            }
            case 'typography': {
                return (
                    <React.Fragment key={`typography-${(Math.random() * 10).toString()}`}>
                        <Typography
                            color={element.color}
                            content={parseContent(element.content)}
                            variant={element.variant}
                        />
                    </React.Fragment>
                );
            }
            case 'section-header':
                return (
                    <React.Fragment key={`section-header-${(Math.random() * 10).toString()}`}>
                        <SectionHeader level={element.level} isSubheader={!!element.subheader}>
                            {element.content}
                        </SectionHeader>
                    </React.Fragment>
                );
            case 'break':
                return <br key={`break-${i + 1}`} />;
            case 'divider':
                return <Divider key={`divider-${i + 1}`} />;
            case 'link':
                return (
                    <React.Fragment key={`link-${i + 1}`}>
                        {element.prefix ? <TextBlock style={{ color: '#5e5e5e' }}>{element.prefix}</TextBlock> : null}
                        <a href={element.href} rel="noopener noreferrer" target="_blank">
                            <TextBlock style={{ color: '#1890ff', fontWeight: 500 }}>{element.content}</TextBlock>
                        </a>
                        {element.suffix ? <TextBlock style={{ color: '#5e5e5e' }}>{element.suffix}</TextBlock> : null}
                    </React.Fragment>
                );
            case 'image':
                return (
                    <Image
                        key={`image-${i + 1}`}
                        src={element.src}
                        alt={element.alt}
                        width={element.isFullWidth ? '100%' : element.customWidth || 'auto'}
                        style={{
                            display: 'inline-block',
                            verticalAlign: 'middle',
                        }}
                    />
                );
            case 'pdf_file':
                return (
                    <React.Fragment key={`pdf-${i + 1}`}>
                        <p>
                            <TextBlock style={{ color: '#1890ff', fontWeight: 500 }}>{element.alt}</TextBlock>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button
                                onClick={async (): Promise<void> => {
                                    if (IS_BROWSER) {
                                        window.open(element.src, '_blank');
                                    }
                                }}>
                                View
                            </Button>
                            {element.showDlButton ? (
                                <Button
                                    onClick={async (): Promise<void> => {
                                        if (IS_BROWSER) {
                                            const file1 = await (await fetch(element.src)).blob();
                                            const reader = new FileReader();

                                            reader.addEventListener(
                                                'load',
                                                () => {
                                                    if (reader.result) {
                                                        const link = document.createElement('a');
                                                        link.href = reader.result.toString();
                                                        link.download = 'document.pdf';
                                                        link.click();
                                                    }
                                                },
                                                false,
                                            );

                                            if (file1) {
                                                reader.readAsDataURL(file1);
                                            }
                                        }
                                    }}>
                                    Download
                                </Button>
                            ) : null}
                        </p>
                    </React.Fragment>
                );
            case 'list':
                return (
                    <AntList split={false} key={Math.random().toString()}>
                        {element.content.map((listItem, ii) => {
                            if (typeof listItem === 'string') {
                                return (
                                    <AntList.Item key={`list-string-${ii + 1}`}>
                                        <AntList.Item.Meta
                                            style={{ textAlign: 'justify', whiteSpace: 'pre-wrap' }}
                                            description={
                                                <TextBlock style={{ color: '#5e5e5e', whiteSpace: 'pre-wrap' }}>
                                                    {listItem}
                                                </TextBlock>
                                            }
                                            avatar={!!element.icon && getIconFor(element.icon)}
                                        />
                                    </AntList.Item>
                                );
                            }
                            return (
                                <AntList.Item key={`list-object-${ii + 1}`}>
                                    <AntList.Item.Meta
                                        style={{ textAlign: 'justify', whiteSpace: 'pre-wrap' }}
                                        title={
                                            listItem.link ? (
                                                <a href={listItem.link} rel="noopener noreferrer" target="_blank">
                                                    <TextBlock style={{ textAlign: 'justify', whiteSpace: 'pre-wrap' }}>
                                                        {listItem.primary}
                                                    </TextBlock>
                                                </a>
                                            ) : (
                                                <TextBlock>{listItem.primary}</TextBlock>
                                            )
                                        }
                                        description={
                                            listItem.link ? (
                                                <a href={listItem.link} rel="noopener noreferrer" target="_blank">
                                                    <TextBlock>{listItem.secondary}</TextBlock>
                                                </a>
                                            ) : (
                                                <TextBlock>{listItem.secondary}</TextBlock>
                                            )
                                        }
                                        avatar={
                                            (!!listItem.icon || !!element.icon) &&
                                            getIconFor(listItem.icon || element.icon)
                                        }
                                    />
                                </AntList.Item>
                            );
                        })}
                    </AntList>
                );
            case 'table': {
                // const res = element.headers.reduce((ac, a) => ({ ...ac, [a]: '' }), {});
                const keys = element.headers;
                const data = element.content.map(row => {
                    return keys.reduce((obj, key, index) => {
                        // eslint-disable-next-line no-param-reassign
                        obj[key] = row[index];
                        return obj;
                    }, {});
                });

                return (
                    <>
                        <AntTable dataSource={data}>
                            {element.headers.map((header, index) => {
                                return (
                                    <Column
                                        key={`column${index + 1}`}
                                        title={header}
                                        dataIndex={header}
                                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                        render={(record: any): JSX.Element => {
                                            if (record.href) {
                                                return (
                                                    <a href={record.href} rel="noopener noreferrer" target="_blank">
                                                        {record.text}
                                                    </a>
                                                );
                                            }
                                            return <TextBlock style={{ fontSize: '14px' }}>{record.text}</TextBlock>;
                                        }}
                                    />
                                );
                            })}
                        </AntTable>
                    </>
                );
            }
            case 'carousel':
                return (
                    <React.Fragment key={`${Math.random().toString()}`}>
                        <Carousel
                            images={element.content}
                            pagination={element.pagination}
                            navigation={element.navigation}
                            speed={element.speed}
                            height={element.height}
                        />
                    </React.Fragment>
                );
            case 'horizontal-image-text':
                return (
                    <Row style={{ width: '100%' }} justify="center" align="middle" key={Math.random().toString()}>
                        {element.imgFirst && (
                            <Col xs={24} sm={12}>
                                <img
                                    key={`image-text-block${i + 1}`}
                                    src={element.img.src}
                                    alt={element.img.alt}
                                    width="100%"
                                />
                            </Col>
                        )}
                        <Col
                            xs={24}
                            sm={12}
                            style={{
                                padding: '2% 5%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                            <Typography content={parseContent(element.content)} />
                        </Col>
                        {!!element.imgFirst || (
                            <Col xs={12}>
                                <img
                                    key={`image-text-block${i + 1}`}
                                    src={element.img.src}
                                    alt={element.img.alt}
                                    width="100%"
                                />
                            </Col>
                        )}
                    </Row>
                );
            case 'html-edit':
                return (
                    <div>
                        {/* <Typography content={parseContent(element.content)} /> */}
                        {/* eslint-disable-next-line react/no-danger */}
                        <div
                            style={{ display: 'block' }}
                            className="ck-content"
                            dangerouslySetInnerHTML={{ __html: element.content }}
                        />
                    </div>
                );
            case 'styled-header':
                return (
                    <React.Fragment key={`${element.content}`}>
                        <StyledHeader color={element.color} content={element.content} size={element.size} />
                    </React.Fragment>
                );
            case 'alert':
                return (
                    <AntAlert
                        type={element.color}
                        message={element.title}
                        description={element.content}
                        showIcon={element.icon}
                    />
                );
            default:
                Sentry.captureEvent({ message: 'Unknown CMS type' });
                return <div />;
        }
    });
};
