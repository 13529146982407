import React from 'react';

// Antd Components
import { Typography } from 'antd';

import { TitleProps } from 'antd/lib/typography/Title';

import styled from 'styled-components';

const { Title } = Typography;

const ColorPrimary = '#8fa8c2';

type TProps = {
    level?: 1 | 2 | 3 | 4 | 5;
    isSubheader?: boolean;
    key?: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children: any;
};

// styled components
const Text = styled(Title)<TitleProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

        font-weight: 500;
        margin-top: 5px;

        padding: 8px;
        border-radius: 4px;
    }
`;

const SectionHeader = ({ children, isSubheader, level }: TProps): JSX.Element => {
    return (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Text
            level={level || 4}
            key={Math.random().toString()}
            style={{
                backgroundColor: isSubheader ? 'transparent' : ColorPrimary,
                color: isSubheader ? 'black' : 'white',
            }}>
            {children}
        </Text>
    );
};

export default SectionHeader;
