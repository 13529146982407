import React from 'react';

// Antd Components
import { Typography } from 'antd';

import { TitleProps } from 'antd/lib/typography/Title';

import styled from 'styled-components';
import { THeaderColors } from '../types/cms';

const { Title } = Typography;

const ColorPrimary = '#3f51b5';

// styled components
const Text = styled(Title)<TitleProps>`
    &&& {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
            sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
        color: white;
        font-weight: 500;
        padding: 10px;
        border-radius: 4px;
        margin-top: 5px;
    }
`;

type TProps = {
    content: string;
    color?: THeaderColors;
    size?: 'auto' | number;
};

const StyledHeader = ({ color, content, size }: TProps): JSX.Element => {
    let backgroundColor = ColorPrimary;
    if (color === 'error') backgroundColor = '#d96277';
    else if (color === 'warning') backgroundColor = '#ffc940';
    else if (color === 'success') backgroundColor = '#6eba71';
    else backgroundColor = '#38b3d9';
    let fontSize = '20px';
    if (size !== 'auto' && !!size) {
        fontSize = `${size}px`;
    }
    return <Text style={{ backgroundColor, fontSize }}>{content}</Text>;
};

export default StyledHeader;
